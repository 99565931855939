

.App {
  text-align: center;
  font-family: 'Muli', sans-serif;
  background-color: #2A364B;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
    padding: 12px;
  }

  .header-text {
    font-family: 'Quicksand', sans-serif;
    font-size: 64px;
    background: -webkit-linear-gradient(left,#63ff5f, #21fcf5, #006ecf);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }



.board-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.list-header {
  font-weight: bold;
  text-align: left;
  padding: 8px;
}

.card-list {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 8px;
  width: 342px;
}

.list-container {
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
}

.card {
  display: flex;
  background-color: #fff;
  width: 292px;
  margin-left: -22px;
  margin-right: 20px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  text-align: start;
  font-size: 14px;
  flex-wrap: wrap;
  font-family: 'Muli', sans-serif;
  color: black;
  overflow-wrap: break-word;
  cursor: pointer;
  box-shadow: 0 0 5 rgba(0,0,0, 0.2);
  flex-direction: row;
  justify-content: space-between;
}

.card-input {
  display: flex;
  background-color: #fff;
  width: 292px;
  margin-top: -24px;
  padding: 8px;
  border-radius: 4px;
  text-align: start;
  flex-grow: 100%;
  font-size: 14px;
  flex-wrap: wrap;
  font-family: 'Muli', sans-serif;
  color: black;
  cursor: pointer;
}

.card-input:focus {
  border: none;
}

input[type="text"] {
  font-family: 'Muli', sans-serif;
}

.button-signed-in {
  font-family: muli;
  font-size: 18px;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  color: black;
  position: absolute;
  margin-top: -80px;
  right: 10px;
}
.button-signed-in:hover {
    cursor: pointer;
    background: green;
    color: black;
}

.button-signed-out {
  font-family: muli;
  font-size: 18px;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  margin-top: 10px;
  color: black;
  
}
.button-signed-out:hover {
    cursor: pointer;
    background: green;
    color: black;
}

.card-text {
  display: flex;
  margin-left: 0px;
  width: 242px;
}

.trash-span {
  position: absolute right;
  margin-left: 260px;
  margin-top: -20px;
  justify-content: right;
}

@media (max-width: 600px) {
  .trash-span {
    position: absolute right;
    margin-left: 250px;
    margin-top: -20px;
    justify-content: right;
  }
  .button-signed-in {
    font-family: muli;
    font-size: 12px;
    padding: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    color: black;
    position: absolute;
    margin-top: -70px;
    right: 10px;
  }
}

@media (max-width: 450px) {
  .trash-span {
    position: absolute right;
    margin-left: 250px;
    margin-top: -20px;
    justify-content: right;
  }
  .button-signed-in {
    font-family: muli;
    font-size: 8px;
    padding: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    color: black;
    position: absolute;
    margin-top: -30px;
    right: 10px;
  }
}



